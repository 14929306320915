import React, { useState, useEffect, useRef, useContext } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  InputBase,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
  IconButton,
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Drawer,
  Grid2
} from "@mui/material";
import Logout from '@mui/icons-material/Logout';
import LogoIcon from "../logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import { styled, alpha } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db, auth } from "../firebaseConfig";
import { signOut } from "firebase/auth";
import { AuthContext, rolesEnum } from "../AuthContext";
import PeopleIcon from '@mui/icons-material/People';


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from theme
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Navbar = () => {
  const [searchQuery, setSearchQuery] = useState(""); // Texto digitado
  const [filteredEvents, setFilteredEvents] = useState([]); // Eventos filtrados
  const [isLoading, setIsLoading] = useState(false); // Estado de carregamento
  const { user, hasPermission } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const searchRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate(); // Inicializa o hook de navegação

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Busca eventos no Firestore com base na query do usuário
  const fetchFilteredEvents = async (queryText) => {
    if (!queryText.trim()) {
      setFilteredEvents([]);
      return;
    }

    setIsLoading(true); // Inicia o loading
    try {
      const queryLowercase = queryText.toLowerCase().split(/\s+/).filter((word) => word.length >= 3);

      // Firestore query para buscar eventos cujo nome contém o texto buscado
      const eventsCollection = collection(db, "events");
      const q = query(
        eventsCollection,
        where("searchData", "array-contains-any", queryLowercase) // Firestore busca por prefixo
      );
      const querySnapshot = await getDocs(q);

      const results = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })).filter((doc) =>
        queryLowercase.every(item => doc.searchData.includes(item))
      );
      setFilteredEvents(results);
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
      setFilteredEvents([]);
    } finally {
      setIsLoading(false); // Finaliza o loading
    }
  };

  // Atualizar a lista de eventos filtrados conforme o usuário digita
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchFilteredEvents(searchQuery);
    }, 300); // Debounce de 300ms para evitar chamadas frequentes ao Firestore

    return () => clearTimeout(delayDebounceFn); // Limpar debounce ao desmontar ou ao atualizar o texto
  }, [searchQuery]);

  // Verifica o estado de autenticação ao carregar o componente
  useEffect(() => {
    /*const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser); // Atualiza o estado com o usuário logado
    });*/

    // Detecta cliques fora do campo de busca
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchFocused(false); // Fecha o dropdown
      }
    };

    // Adiciona e remove o listener para cliques fora
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      //unsubscribe(); // Limpa o listener do auth ao desmontar
    };
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      window.location.reload(); // Recarrega a página após logout
    } catch (error) {
      console.error("Erro ao fazer logout", error);
    }
  };

  const handleProfile = () => {
    handleClose(); // Fecha o menu
    navigate("/profile"); // Redireciona para a página de perfil
  };

  const handleUserManagement = () => {
    handleClose(); // Fecha o menu
    navigate("/manageusers"); // Redireciona para a página de perfil
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#333" }}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid2 container spacing={0} sx={{ width: "100%" }}>
            <Grid2 size={{ xs: 2, md: 3 }}>
              <Link to="/" style={{ textDecoration: "none", color: "#FFF", display: "flex", alignItems: "center" }}>
                <img
                  src={LogoIcon}
                  alt="Logo"
                  style={{
                    width: "40px",
                    height: "40px",
                  }}
                />
                <Typography variant="h6" sx={{
                  flex: 1,
                  textAlign: "left",
                  fontWeight: "bold",
                  ml: 1,
                  display: { xs: 'none', md: 'block' }
                }}>
                  Mapa Histórico
                </Typography>
              </Link>
            </Grid2>
            <Grid2 size="grow">
              <Box sx={{ width: "100%" }} ref={searchRef}>
                <Search>
                  <StyledInputBase
                    placeholder="Buscar eventos..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onFocus={() => setIsSearchFocused(true)}
                    sx={{ width: "100%" }}
                  />
                </Search>
                {isSearchFocused && (
                  <Box
                    sx={{
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                      maxHeight: "300px",
                      overflowY: "auto",
                      zIndex: 1000,
                      width: "100%",
                    }}
                  >
                    <Grid2 container spacing={0} sx={{ px: { xs: 2, md: 3, }, }} >
                      <Grid2 size={{ xs: 8, md: 6 }} offset={{ xs: 2, md: 3 }}>
                        <Paper sx={{ backgroundColor: "#222", }}>
                          {isLoading ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100px",
                              }}
                            >
                              <CircularProgress color="inherit" />
                            </Box>
                          ) : (
                            <List>
                              {filteredEvents.length > 0 ? (
                                filteredEvents.map((event) => (
                                  <Link
                                    to={`/event/${event.id}`}
                                    key={event.id}
                                    style={{ textDecoration: "none", color: "#fff" }}
                                    onClick={() => setIsSearchFocused(false)}
                                  >
                                    <ListItem
                                      sx={{
                                        "&:hover": { backgroundColor: "#444" },
                                      }}
                                    >
                                      <ListItemText
                                        primary={event.name}
                                        secondary={event.shortDescription}
                                      />
                                    </ListItem>
                                  </Link>
                                ))
                              ) : (
                                <Box sx={{ padding: 2, textAlign: "center", color: "#aaa" }}>
                                  Nenhum evento encontrado.
                                </Box>
                              )}
                            </List>
                          )}
                        </Paper>
                      </Grid2>
                    </Grid2>
                  </Box>
                )}
            </Box>
          </Grid2>
          <Grid2 size={{ xs: 2, md: 3 }} sx={{ display: "flex", justifyContent: "flex-end" }}>
            {user ? (
              <>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32 }}>{user.displayName ? user.displayName[0].toUpperCase() : ""}</Avatar>
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        '&::before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={handleProfile}>
                    <Avatar /> Perfil
                  </MenuItem>
                  {hasPermission(rolesEnum.admin) && (
                  <MenuItem onClick={handleUserManagement}>
                    <ListItemIcon>
                      <PeopleIcon fontSize="small" />
                    </ListItemIcon>
                     Gerenciar usuários
                  </MenuItem>)}
                  <Divider />
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                  <Link to="/login" style={{ textDecoration: "none", marginRight: "10px" }} sx={{ display: "flex" }}>
                    <Button variant="text">
                      Login
                    </Button>
                  </Link>
                  <Link to="/signup" style={{ textDecoration: "none" }} sx={{ display: "flex" }}>
                    <Button variant="text" >
                      Sign Up
                    </Button>
                  </Link>
                </Box>
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  sx={{ display: { xs: "flex", sm: "none" } }}
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
              </>
            )}
          </Grid2>
        </Grid2>
      </Toolbar>
    </AppBar >

      {/* Drawer (Menu Hambúrguer) */ }
      < Drawer anchor = "right" open = { drawerOpen } onClose = { toggleDrawer(false) } >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem button component={Link} to="/login">
              <Button variant="text">
                Login
              </Button>
            </ListItem>
            <ListItem button component={Link} to="/signup">
              <Button variant="text" >
                Cadastre-se
              </Button>
            </ListItem>
          </List>
        </Box>
      </Drawer >
    </>
  );
};

export default Navbar;