import React, { useState, useEffect } from "react";
import {
    Box,
    Modal,
} from "@mui/material";
import MapaDisplay from "./MapDisplay"
const MapaModal = ({ open, onClose, place }) => {
    const [selectedPlaces, setSelectedPlaces] = useState([]);

    useEffect(() => {
        if (place) {
            setSelectedPlaces([
                {
                    mapId: place.mapId,
                    properties: {
                        color: "#1976D2",
                        leader: place.leader,
                        regime: place.regime,
                    }
                }
            ]);
        }
    }, [place]);

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={modalStyle}>
                <MapaDisplay countries={selectedPlaces} popup={false} />
            </Box>
        </Modal>
    )
}

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
    borderRadius: 1,
    maxWidth: {  xs: "80%", sm: "80%", md: "1200px", }
};

export default MapaModal;