import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Polygon, Popup } from "react-leaflet";
import { Link } from "react-router-dom";
import { getCountryData } from "../utils/geoDataLoader"; // Função para carregar dados geográficos
import "leaflet/dist/leaflet.css";

const MapDisplay = ({ countries, popup = true }) => {
  const [geoData, setGeoData] = useState([]);

  useEffect(() => {
    const fetchGeoData = async () => {
      if (countries) {
        const data = await Promise.all(
          countries.map(async (country) => {
            const countryData = await getCountryData(country.mapId);
            if (countryData) {
              countryData.properties = country.properties;
              return countryData;
            }
            return null;
          })
        );
        setGeoData(data.filter((item) => item !== null));
      }
    };

    fetchGeoData();
  }, [countries]);

  const bounds = [
    [-90, -180],
    [90, 180],
  ];

  return (
    <div style={{ height: "700px", width: "100%" }}>
      <MapContainer
        center={[30, 0]}
        zoom={2}
        minZoom={2}
        maxZoom={5}
        maxBounds={bounds}
        maxBoundsViscosity={2}
        worldCopyJump={false}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="http://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}"
          attribution='&copy; <a href="https://www.arcgis.com/" target="_blank">ArcGIS Online</a> contributors'
        />

        {geoData.map((data, index) => {
          if (data && data.geometry) {
            const { type, coordinates } = data.geometry;
            const { regime, leader, events, color } = data.properties;

            const geoStyle = {
              fillColor: color,
              color: "white",
              weight: 2,
              opacity: 1,
              fillOpacity: 0.7,
            };

            if (type === "Polygon") {
              // Renderiza um único polígono
              const convertedCoords = coordinates[0].map(([lon, lat]) => [lat, lon]); // Converte para [lat, lon]
              return (
                <>
                  <Polygon key={index} positions={convertedCoords} pathOptions={geoStyle}>
                    {popup && (
                      <Popup>
                        <div>
                          <h4>{data.name}</h4>
                          <p><strong>Líder:</strong> {leader || "Desconhecido"}</p>
                          <p><strong>Regime:</strong> {regime || "Desconhecido"}</p>
                          <p><strong>Eventos Importantes:</strong></p>
                          <ul>
                            {events && events.length > 0 ? (
                              events.map((event, i) => (
                                <li key={i}>
                                  <Link to={`/event/${event.id}`} style={{ textDecoration: "none" }}>
                                    {event.name}: {event.startYear} - {event.endYear}
                                  </Link>
                                </li>
                              ))
                            ) : (
                              <li>Sem eventos registrados</li>
                            )}
                          </ul>
                        </div>
                      </Popup>
                    )}
                  </Polygon>
                </>
              );
            } else if (type === "MultiPolygon") {
              // Renderiza múltiplos polígonos
              return coordinates.map((polygonCoords, i) => {
                const convertedCoords = polygonCoords[0].map(([lon, lat]) => [lat, lon]); // Converte para [lat, lon]
                return (
                  <Polygon key={`${index}-${i}`} positions={convertedCoords} pathOptions={geoStyle}>
                    {popup && (
                      <Popup>
                        <div>
                          <h4>{data.name}</h4>
                          <p><strong>Líder:</strong> {leader || "Desconhecido"}</p>
                          <p><strong>Regime:</strong> {regime || "Desconhecido"}</p>
                          <p><strong>Eventos Importantes:</strong></p>
                          <ul>
                            {events && events.length > 0 ? (
                              events.map((event, i) => (
                                <li key={i}>
                                  <Link to={`/event/${event.id}`} style={{ textDecoration: "none" }}>
                                    {event.name}: {event.startYear} - {event.endYear}
                                  </Link>
                                </li>
                              ))
                            ) : (
                              <li>Sem eventos registrados</li>
                            )}
                          </ul>
                        </div>
                      </Popup>
                    )}
                  </Polygon>
                );
              });
            }
          }
          return null;
        })}
      </MapContainer>
    </div>
  );
};

export default MapDisplay;