import React, { useState, useEffect } from "react";
import MapDisplay from "../components/MapDisplay";
import RegionInfo from "../components/RegionInfo";
import { useParams } from "react-router-dom";
import { doc, getDoc, collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../firebaseConfig";
import {
  Container,
  Typography,
  Card,
  CardMedia,
  Stack,
  Skeleton,
  Box,
  Divider,
} from "@mui/material";
import ActionsButtons from "../components/ActionsButtons";


function Event() {
  const { eventId } = useParams(); // Captura o ID do evento na URL
  const [event, setEvent] = useState(null); // Dados do evento atual
  const [loading, setLoading] = useState(true);
  const [countriesData, setCountriesData] = useState([]); // Dados processados para o mapa



  useEffect(() => {
    const fetchEventData = async () => {
      // Busca o evento atual (a)
      const eventDoc = doc(db, "events", eventId);
      const eventSnap = await getDoc(eventDoc);

      if (eventSnap.exists()) {
        const eventData = eventSnap.data();
        setEvent(eventData);

        // Após obter o evento atual, busca eventos (b) com interseção de período
        const eventsCollection = collection(db, "events");
        const q = query(
          eventsCollection,
          where("endYear", ">=", eventData.startYear), // Eventos com fim >= início do evento (a)
          where("startYear", "<=", eventData.endYear), // Eventos com início <= fim do evento (a)
          orderBy("endYear"),
          orderBy("startYear")
        );

        const querySnapshot = await getDocs(q);

        // Agrupar eventos por `mapId`
        if (!querySnapshot) {
          console.log(`Sem eventos no período entre ${eventData.startYear} - ${eventData.endYear}!`);
          fetchEventData();
          return;
        }

        const groupedPlaces = {};
        querySnapshot.forEach((doc) => {
          const eventB = doc.data();
          eventB.places.forEach((country) => {
            if (!groupedPlaces[country.mapId]) {
              groupedPlaces[country.mapId] = {
                mapId: country.mapId,
                color: doc.id === eventId ? "#fa6d4d" : "#1976D2",
                leaders: new Set(), // Para evitar duplicatas
                events: new Set(),  // Para evitar duplicatas
                regimes: new Set(),
              };
            }

            // Adiciona líderes e eventos ao agrupamento
            groupedPlaces[country.mapId].leaders.add(country.leader);
            groupedPlaces[country.mapId].events.add({
              name: eventB.name,
              id: doc.id,
              startYear: eventB.startYear,
              endYear: eventB.endYear
            });
            groupedPlaces[country.mapId].regimes.add(country.regime);
          });
        });

        // Converter Sets para arrays e preparar os dados para o mapa
        const processedCountries = Object.values(groupedPlaces).map((country) => ({
          mapId: country.mapId,
          properties: {
            color: country.color,
            leader: Array.from(country.leaders).join(", "), // Junta líderes com vírgula
            events: Array.from(country.events), // Lista de eventos
            regime: Array.from(country.regimes).join(","),
          },
        }));
        setCountriesData(processedCountries); // Atualiza os dados para o mapa

      } else {
        console.log("Evento não encontrado!");
      }
    };
    setLoading(false);
    fetchEventData();
  }, [eventId]);

  if (loading || !event) {
    return (
      <Container maxWidth="lg" style={{ marginTop: "2rem" }}>
        <Stack spacing={1}>
          <Skeleton variant="rounded" height={400} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={100} />
          <Skeleton variant="text" sx={{ fontSize: '80px' }} width={600} />
          <Skeleton variant="text" sx={{ fontSize: '1.5rem' }} width={150} />
          <Skeleton variant="rounded" height={200} />
        </Stack>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: "2rem" }}>
      {/* Imagem de destaque */}
      <Card>
        <CardMedia
          component="img"
          height="400"
          image={event.thumbnail || "https://via.placeholder.com/800x400"}
          alt={event.name}
        />
      </Card>

      {/* Informações do evento */}
      <Box my={4}>
        <Typography variant="caption" color="textSecondary" gutterBottom>
          Autor: {event.author || "Desconhecido"}
        </Typography>
        <Typography variant="h3" component="h1" gutterBottom>
          {event.name} <ActionsButtons eventId={eventId} />
        </Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Período: {event.startYear} - {event.endYear}
        </Typography>
        <Divider style={{ margin: "1rem 0" }} />
        <Typography variant="body1" paragraph>
          {event.description || "Descrição não disponível."}
        </Typography>
      </Box>

      {/* Mapa Interativo */}
      <Box my={4}>
        <Typography variant="h5" gutterBottom>
          Mapa Histórico - {event.name}
        </Typography>
        <MapDisplay countries={countriesData} />
      </Box>
      <RegionInfo />
    </Container>
  );
}

export default Event;