import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { rolesEnum } from "./AuthContext";
import Home from "./pages/Home";
import Event from "./pages/Event";
import EventForm from "./pages/EventForm";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";
import EditProfile from "./pages/EditProfile";
import Navbar from "./components/Navbar";
import PrivateRoute from "./components/PrivateRoute"; // Importa a rota privada
import FloatingAddButton from "./components/FloatingAddButton";
import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Footer from "./components/Footer";

// Criação do tema escuro
const darkTheme = createTheme({
  palette: {
    mode: "dark", // Define o modo como "dark"
  },
  typography: {
    fontFamily: "'Roboto', sans-serif", // Você pode adicionar ou personalizar as fontes aqui
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}ß>
          {/* Barra de navegação */}
          <Navbar />

          {/* Rotas */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/event/:eventId" element={<Event />} />
            <Route path="/events/:eventId" element={<PrivateRoute element={EventForm} permission={rolesEnum.contributor} />} />
            <Route path="/event" element={<PrivateRoute element={EventForm} permission={rolesEnum.contributor} />} />
            <Route path="/manageusers" element={<PrivateRoute element={UserManagement} permission={rolesEnum.admin} />} />
            <Route path="/profile" element={<PrivateRoute element={EditProfile} />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
          </Routes>

          <FloatingAddButton />
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
