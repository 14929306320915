import {
    Card,
    Typography,
    CardMedia,
    CardContent,
    CardActions,
    CardHeader,
    Stack,
    Chip,
} from "@mui/material";
import ActionsButtons from "../components/ActionsButtons";

function EventCard({ event, onCardClick, onTagClick }) {
    return (

        <Card sx={{
                backgroundColor: "#222",
                color: "#fff",
                minHeight: "330px",
                maxHeight: "330px",
                cursor: "pointer",
                "&:hover": { boxShadow: 6 },
            }}
            onClick={() => onCardClick(event)}>
            <CardMedia
                component="img"
                height="140"
                image={event.thumbnail || "https://via.placeholder.com/140"}
                alt={event.name} />
            <CardHeader
                action={
                    <ActionsButtons eventId={event.id} />
                }
                title={event.name}
                subheader={`${event.startYear} - ${event.endYear}`}
                sx={{ pb: 0 }}
            />
            <CardContent sx={{ py: 1, height: "50px" }}>
                <Typography variant="body2">{event.shortDescription}</Typography>
            </CardContent>
            <CardActions disableSpacing>
                <Stack direction="row" spacing={1} sx={{ pt: 1 }}>
                    {event.tags && event.tags.map((tag, index) => (
                        <Chip
                            key={index}
                            variant="outlined"
                            size="small"
                            label={tag}
                            onClick={(e) => {
                                e.stopPropagation();
                                onTagClick(tag);
                            }}
                            sx={{ cursor: "pointer" }} />
                    ))}
                </Stack>
            </CardActions>
        </Card>
    );

}

export default EventCard;