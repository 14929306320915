import React, { useContext } from "react";
import { AuthContext, rolesEnum } from "../AuthContext";
import { Link } from "react-router-dom";
import {
  IconButton,
  Tooltip,
} from "@mui/material";
import EditIcon from '@mui/icons-material/EditNote';

function ActionsButtons({ eventId }) {
  const { user, hasPermission } = useContext(AuthContext);

  return (
    <span>
      {user && hasPermission(rolesEnum.contributor) && (
        <Tooltip title="Editar">
        <Link to={`/events/${eventId}`} style={{ textDecoration: "none" }}>
          <IconButton size="small">
            <EditIcon />
          </IconButton>
        </Link>
        </Tooltip>
      )}
    </span>
  );
}

export default ActionsButtons;