/**
 * Gera um array de termos de busca com prefixos a partir de um ou mais textos.
 * 
 * @param {...string} texts - Textos para gerar os termos.
 * @param {number} minPrefixLength - Tamanho mínimo do prefixo. (padrão: 3)
 * @returns {string[]} Um array de termos de busca.
 */
export const generateSearchData = (minPrefixLength = 3, ...texts) => {
    const searchTerms = new Set(); // Usar Set para evitar duplicatas
  
    texts.forEach((text) => {
      text
        .toLowerCase() // Deixa tudo em minúsculas
        .split(/\s+/) // Divide o texto por espaços
        .filter((word) => word.length >= minPrefixLength) // Filtra palavras curtas
        .forEach((word) => {
          for (let i = minPrefixLength; i <= word.length; i++) {
            searchTerms.add(word.slice(0, i)); // Adiciona prefixos ao Set
          }
        });
    });
  
    return Array.from(searchTerms); // Retorna os termos como um array
  };