import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import {
  Typography,
  Grid2,
  Skeleton,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import EventCard from "../components/EventCard";
import FilterAltIcon from '@mui/icons-material/FilterAltOutlined';

const Home = () => {
  const [events, setEvents] = useState([]); // Armazena os eventos
  const [filteredEvents, setFilteredEvents] = useState([]); // Eventos filtrados
  const [loading, setLoading] = useState(true); // Controle de carregamento
  const [tags, setTags] = useState([]); // Todas as tags existentes
  const [selectedTags, setSelectedTags] = useState([]); // Tags selecionadas para o filtro
  const [searchParams, setSearchParams] = useSearchParams(); // Controle de parâmetros na URL
  const navigate = useNavigate();

  const fetchEvents = async () => {
    try {
      const eventsCollection = collection(db, "events");
      const eventSnapshot = await getDocs(eventsCollection);

      // Mapeia os documentos da coleção para o estado
      const eventList = eventSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(), // Inclui todos os campos do documento
      }));

      // Extrai todas as tags únicas dos eventos
      const allTags = [
        ...new Set(eventList.flatMap((event) => event.tags || [])),
      ];

      setTags(allTags);
      setEvents(eventList); // Atualiza o estado com os eventos
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
    } finally {
      setLoading(false); // Finaliza o carregamento
    }
  };

  // Atualiza eventos filtrados ao alterar as tags selecionadas
  useEffect(() => {
    if (selectedTags.length === 0) {
      setFilteredEvents(events);
    } else {
      const filtered = events.filter((event) =>
        selectedTags.every((tag) => event.tags?.includes(tag))
      );
      setFilteredEvents(filtered);
    }
    // Atualiza a URL com as tags selecionadas
    setSearchParams({ tags: selectedTags.join(",") });
  }, [selectedTags, events, setSearchParams]);

  // Carregar eventos ao montar o componente
  useEffect(() => {
    fetchEvents();

    // Recupera tags da URL ao carregar a página
    const tagsFromUrl = searchParams.get("tags")
      ? searchParams.get("tags").split(",").filter(tag => tag.trim() !== "")
      : [];
    setSelectedTags(tagsFromUrl);
  }, [searchParams]);

  // Adiciona uma tag ao filtro
  const handleAddTag = (tag) => {
    if (tag && !selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
      setSearchParams({ tags: selectedTags.join(",") });
    }
  };

  const handleCardClick = (event) => {
    navigate(`/event/${event.id}`);
  }

  return (
    <Box sx={{ px: 3, py: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          marginBottom: 3,
          alignItems: "center",
        }}
      >
        <FilterAltIcon />
        {/* Campo de Autocomplete */}
        <Autocomplete
          multiple
          options={tags}
          value={selectedTags} // Sincroniza o Autocomplete com as tags selecionadas
          onChange={(event, value) => setSelectedTags(value)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="medium" 
            />
          )}
          sx={{ flex: 1, minWidth: "250px" }}
        />
      </Box>

      {/* Lista de Eventos */}
      {loading ? (
        <Grid2 container spacing={3}>
          {[...Array(6)].map((_, index) => (
            <Grid2 size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Skeleton variant="rounded" height={300} />
            </Grid2>
          ))}
        </Grid2>
      ) : filteredEvents.length > 0 ? (
        <Grid2 container spacing={3}>
          {filteredEvents.map((event) => (
            <Grid2 size={{ xs: 12, sm: 6, md: 4, }} key={event.id}>
              <EventCard event={event} onTagClick={handleAddTag} onCardClick={handleCardClick} />
            </Grid2>
          ))}
        </Grid2>
      ) : (
        <Typography
          variant="h6"
          sx={{
            color: "#fff",
            textAlign: "center",
            marginTop: 3,
          }}
        >
          Nenhum evento encontrado.
        </Typography>
      )}
    </Box>
  );
};

export default Home;
