import React, { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { doc, getDoc, updateDoc, addDoc, collection, deleteDoc } from "firebase/firestore";
import {
    TextField,
    Button,
    Typography,
    Grid2,
    Stack,
    Modal,
    Box,
    Skeleton,
    Autocomplete,
    Chip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PlaceTable from "../components/PlaceTable"; // Importe o componente
import { toast } from "react-toastify";
import { generateSearchData } from "../utils/searchDataGenerator";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
};

const EventForm = () => {
    const { eventId } = useParams(); // Obtém o ID do evento da URL
    const navigate = useNavigate(); // Usado para redirecionar após a edição
    const [event, setEvent] = useState(null); // Armazena os dados do evento
    const [name, setName] = useState("");
    const [shortDescription, setShortDescription] = useState("");
    const [description, setDescription] = useState("");
    const [startYear, setStartYear] = useState("");
    const [endYear, setEndYear] = useState("");
    const [places, setPlaces] = useState([]);
    const [thumbnail, setThumbnail] = useState("");
    // Estados para os modais
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [tags, setTags] = useState([]); // Novo estado para tags

    // Carregar os dados do evento do Firebase
    useEffect(() => {
        if (eventId) {
            const fetchEvent = async () => {
                const eventDoc = doc(db, "events", eventId); // Pega o documento específico no Firestore
                const docSnap = await getDoc(eventDoc);

                if (docSnap.exists()) {
                    const eventData = docSnap.data();
                    setEvent(eventData);
                    setName(eventData.name);
                    setDescription(eventData.description);
                    setStartYear(eventData.startYear);
                    setEndYear(eventData.endYear);
                    setThumbnail(eventData.thumbnail);
                    setShortDescription(eventData.shortDescription);
                    setPlaces(eventData.places || []); // Certifique-se de que a lista de países seja um array
                    setTags(eventData.tags || []); // Inicializa as tags do evento
                } else {
                    console.log("Evento não encontrado!");
                }
            };

            fetchEvent();
        } else {
            setEvent({});
        }
    }, [eventId]);

    // Atualizar os dados no Firebase
    const handleSubmit = async (e) => {
        e.preventDefault();
        setOpenUpdateModal(true); // Abre o modal de confirmação para atualização
    };

    const confirmSubmit = async () => {
        let currentEventId = eventId;
        try {
            if (currentEventId) {
                const eventDoc = doc(db, "events", eventId);
                await updateDoc(eventDoc, {
                    name,
                    searchData: generateSearchData(
                        3,
                        endYear.toString(),
                        startYear.toString(),
                        name),
                    shortDescription,
                    description,
                    startYear,
                    endYear,
                    thumbnail,
                    places,
                    tags, // Inclui as tags no Firestore
                });
                toast.success("Evento atualizado com sucesso!");
            } else {
                // Modo de criação
                const docRef = await addDoc(collection(db, "events"), {
                    name,
                    shortDescription,
                    description,
                    startYear,
                    endYear,
                    thumbnail,
                    places,
                    searchData: generateSearchData(3, endYear.toString(), startYear.toString(), name),
                    tags
                });
                toast.success("Evento criado com sucesso!");
                currentEventId = docRef.id;
            }
        } catch (error) {
            console.error("Erro ao atualizar evento: ", error);
            toast.error("Erro ao atualizar evento!");
        } finally {
            setOpenUpdateModal(false);
            navigate(`/event/${currentEventId}`);
        }
    };

    const handleUpdateCountries = (updatedCountries) => {
        setPlaces(updatedCountries); // Atualiza a lista de países
    };

    const handleDelete = async () => {
        setOpenDeleteModal(true); // Abre o modal de confirmação para exclusão
    };

    const confirmDelete = async () => {
        try {
            const eventDoc = doc(db, "events", eventId);
            await deleteDoc(eventDoc);
            toast.success("Evento excluído com sucesso!");
            navigate("/"); // Redireciona para a página inicial
        } catch (error) {
            console.error("Erro ao excluir evento: ", error);
            toast.error("Erro ao excluir evento!");
        } finally {
            setOpenDeleteModal(false); // Fecha o modal
        }
    };

    if (!event) {
        return (
            <Grid2 container spacing={2} sx={{ margin: "0 auto", padding: 3 }}>
                <Grid2 size={{ xs: 12, sm: 12, md: 8, }} offset={{ xs: 0, sm: 0, md: 2, }}>
                    <Typography variant="h4"><Skeleton variant="text" width={300} /></Typography>
                    <Typography variant="h5" sx={{ marginTop: 4 }}><Skeleton variant="text" width={300} /></Typography>
                    <Skeleton variant="rounded" sx={{ mt: 2 }} height={55} />
                    <Skeleton variant="rounded" sx={{ mt: 2 }} height={55} />
                    <Skeleton variant="rounded" sx={{ mt: 2 }} height={130} />
                    <Skeleton variant="rounded" sx={{ mt: 2 }} height={55} />
                    <Skeleton variant="rounded" sx={{ mt: 2 }} height={55} />
                    <Skeleton variant="rounded" sx={{ mt: 2 }} height={55} />
                </Grid2>
            </Grid2>
        )
    }; // Exibe uma mensagem até que os dados do evento sejam carregados

    return (
        <>
            <Grid2 container spacing={2} sx={{ margin: "0 auto", padding: 3 }}>
                <Grid2 size={{ xs: 12, sm: 12, md: 8, }} offset={{ xs: 0, sm: 0, md: 2, }}>
                    <Typography variant="h5" gutterBottom>
                        {eventId ? "Editar Evento" : "Adicionar Evento"}
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid2 container spacing={2}>
                            <Grid2 size={{ xs: 12, sm: 12, md: 12, }}>
                                <Typography variant="h6" sx={{ marginTop: 3, paddingBottom: 3 }}>
                                    Informações
                                </Typography>
                                <TextField
                                    label="Nome do Evento"
                                    fullWidth
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </Grid2>
                            {/* Descrição Curta */}
                            <Grid2 size={{ xs: 12, sm: 12, md: 12, }}>
                                <TextField
                                    label="Descrição Curta"
                                    fullWidth
                                    value={shortDescription}
                                    onChange={(e) => setShortDescription(e.target.value)}
                                    required
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 12, md: 12, }}>
                                <TextField
                                    label="Descrição"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    required
                                />
                            </Grid2>
                            {/* Ano de Início */}
                            <Grid2 size={{ xs: 12, sm: 12, md: 12, }}>
                                <TextField
                                    label="Ano de Início"
                                    fullWidth
                                    value={startYear}
                                    onChange={(e) => setStartYear(e.target.value)}
                                    type="number"
                                    required
                                />
                            </Grid2>
                            {/* Ano de Fim */}
                            <Grid2 size={{ xs: 12, sm: 12, md: 12, }}>
                                <TextField
                                    label="Ano de Fim"
                                    fullWidth
                                    value={endYear}
                                    onChange={(e) => setEndYear(e.target.value)}
                                    type="number"
                                    required
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 12, md: 12, }}>
                                <TextField
                                    label="Imagem (URL)"
                                    fullWidth
                                    value={thumbnail}
                                    onChange={(e) => setThumbnail(e.target.value)}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 12, md: 12 }}>
                                <Autocomplete
                                    multiple
                                    freeSolo
                                    options={[]}
                                    value={tags}
                                    onChange={(event, newValue) => setTags(newValue)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip label={option} {...getTagProps({ index })} key={index} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label="Tags" placeholder="Adicionar Tags" />
                                    )}
                                />
                            </Grid2>
                            <Grid2 size={{ xs: 12, sm: 12, md: 12, }}>
                                <Typography variant="h6" sx={{ marginTop: 3 }}>
                                    Região
                                </Typography>
                                <PlaceTable
                                    places={places}
                                    onUpdatePlaces={handleUpdateCountries}
                                />
                            </Grid2>
                        </Grid2>
                        <Stack direction="row" spacing={2} sx={{ mt: 5 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={{ marginTop: 3 }}
                            >
                                {eventId ? "Atualizar Evento" : "Criar Evento"}
                            </Button>
                            {eventId && (
                                <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{ marginTop: 3 }}
                                    onClick={handleDelete}
                                >
                                    Excluir Evento
                                </Button>
                            )}
                        </Stack>
                    </form>
                </Grid2>
            </Grid2>
            {/* Modal de Confirmação para Exclusão */}
            <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <Box sx={modalStyle}>
                    <Typography variant="h6" gutterBottom>
                        Confirmar Exclusão
                    </Typography>
                    <Typography>
                        Tem certeza de que deseja excluir este evento? Essa ação não pode ser desfeita.
                    </Typography>
                    <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
                        <Button
                            variant="outlined"
                            onClick={() => setOpenDeleteModal(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={confirmDelete}
                        >
                            Confirmar
                        </Button>
                    </Stack>
                </Box>
            </Modal>

            {/* Modal de Confirmação para Atualização */}
            <Modal open={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
                <Box sx={modalStyle}>
                    <Typography variant="h6" gutterBottom>
                        {eventId ? "Confirmar Atualização" : "Confirmar Criação"}
                    </Typography>
                    <Typography>
                        {eventId ? "Tem certeza de que deseja atualizar este evento?" : "Tem certeza de que deseja criar este evento?"}
                    </Typography>
                    <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
                        <Button
                            variant="outlined"
                            onClick={() => setOpenUpdateModal(false)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={confirmSubmit}
                        >
                            Confirmar
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
};

export default EventForm;