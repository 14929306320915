import React from "react";

function RegionInfo() {
  return (
    <div>
      <p>Click sobre o mapa para explorar as informações.</p>
    </div>
  );
}

export default RegionInfo;