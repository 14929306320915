import React, { useState } from "react";
import { auth, db } from "../firebaseConfig";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { Box, TextField, Button, Typography, Alert, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(""); // Limpar erros anteriores

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            if (!user.emailVerified) {
                await signOut(auth);
                toast.error("E-mail não verificado! Por favor, verifique sua caixa de entrada.");
                return;
            }


            // Recupera o papel do usuário no Firestore
            const userDoc = await getDoc(doc(db, "users", user.uid));
            if (userDoc.exists()) {
                navigate("/");
            } else {
                console.error("Usuário não encontrado no Firestore");
            }
            setError("Usuário não encontrado");
            return null
        } catch (err) {
            setError("Erro ao fazer login: " + err.message);
        }
    };

    return (
        <Box
            sx={{
                maxWidth: "400px",
                margin: "auto",
                marginTop: "50px",
                padding: "20px",
                backgroundColor: "#333",
                borderRadius: "8px",
                boxShadow: 3,
            }}
        >
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
                Entrar
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <form onSubmit={handleLogin}>
                <TextField
                    fullWidth
                    label="E-mail"
                    type="email"
                    variant="outlined"
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    sx={{ borderRadius: 1 }}
                />
                <TextField
                    fullWidth
                    label="Senha"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    sx={{ borderRadius: 1 }}
                />
                <Link href="/forgotpassword" variant="body2" color="inherit">
                    Esqueceu a senha?
                </Link>
                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                        marginTop: 2,
                    }}
                >
                    Entrar
                </Button>
            </form>
        </Box>
    );
};

export default Login;