import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import countryBorders from "../countries.json"

export const getCountryData = async (mapId) => {
  try {
    // Busca os dados do mapId no Firestore
    const mapDoc = doc(db, "geoData", mapId);
    const countries = countryBorders.features.reduce((acc, f) => {
      if (mapId === f.properties.iso_a3) {
        acc.push({
          "name": f.properties.name,
          "mapId": f.properties.iso_a3,
          "geometry": f.geometry,
        }); // Apenas adiciona os nomes ao acumulador
      }
      return acc;
    }, []);

    if (countries && countries.length > 0) {
      return countries[0];
    }

    const docSnap = await getDoc(mapDoc);

    if (docSnap.exists()) {
      const data = docSnap.data();

      // Verifica e ajusta o formato das coordenadas para [[]]
      const formattedData = {
        ...data,
        geometry: {
          ...data.geometry,
          coordinates: [formatCoordinates(data.geometry.coordinates)],
        },
      };
      
      return formattedData;
    } else {
      console.error(`GeoData não encontrado para o mapId: ${mapId}`);
      return null;
    }
  } catch (error) {
    console.error(`Erro ao carregar GeoData para o mapId "${mapId}":`, error);
    return null;
  }
};

// Função para formatar as coordenadas no formato [[lat, lng]]
const formatCoordinates = (coordinates) => {
  if (!Array.isArray(coordinates)) {
    throw new Error("As coordenadas devem ser um array.");
  }

  return coordinates.map((coord) => {
    if (coord.lat !== undefined && coord.lng !== undefined) {
      // Converte { lat, lng } para [lat, lng]
      return [coord.lat, coord.lng];
    } else {
      throw new Error("Formato de coordenadas inválido. Esperado { lat, lng }.");
    }
  });
};

export const extractKMLGeoData = (xmlDoc) => {
  const placemarks = xmlDoc.getElementsByTagName("Placemark");
  const maps = [];
  const errors = [];

  for (const placemark of placemarks) {
    const name = placemark.getElementsByTagName("name")[0]?.textContent;
    const coordinatesTag = placemark.getElementsByTagName("coordinates")[0];
    const coordinates = coordinatesTag?.textContent.trim();

    if (!name || !coordinates) {
      errors.push("Um dos Placemarks no KML está incompleto. Ignorando...");
      continue;
    }

    // Converte as coordenadas do KML para um formato Firestore-friendly
    const parsedCoordinates = coordinates.split(/\s+/).map((coord) => {
      const [lat, lng] = coord.split(",").map(Number);
      return { lat: lat, lng: lng }; // Representação como objetos
    });

    const mapId = name.toLowerCase().replace(/\s+/g, "_");
    const jsonData = {
      name,
      geometry: {
        type: "Polygon",
        coordinates: parsedCoordinates, // Salvar como array de objetos
      },
    };
    maps.push({ mapId, ...jsonData })
  };


  return { maps, errors };
};
