import React, { useState, useEffect } from "react";
import {
    doc,
    updateDoc,
} from "firebase/firestore";
import { auth, db } from "../firebaseConfig";
import { updateProfile, updateEmail } from "firebase/auth";
import ChangePassword from "../components/ChangePassword";
import {
    Typography,
    Grid2,
    TextField,
    Button,
} from "@mui/material"
import { toast } from "react-toastify";

const EditProfile = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [passwordModalOpen, setPasswordModalOpen] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            const user = auth.currentUser;

            if (user) {
                setEmail(user.email);
                setName(user.displayName || "");
            }
        };

        fetchUserData();
    }, []);

    const handleSaveProfile = async (e) => {
        e.preventDefault();
        try {
            const user = auth.currentUser;

            if (!user) {
                throw new Error("Nenhum usuário autenticado encontrado.");
            }

            // Atualiza o nome no Firebase Auth
            if (name !== user.displayName) {
                await updateProfile(user, { displayName: name });
            }

            // Atualiza o email
            if (email !== user.email) {
                await updateEmail(user, email);
            }

            await updateDoc(doc(db, "users", user.uid), {
                name,
                email,
            });

            toast.success("Perfil atualizado com sucesso!");
        } catch (error) {
            console.error("Erro ao atualizar perfil:", error);
            toast.error("Erro ao atualizar o perfil. Verifique os dados e tente novamente.");
        }
    };

    return (
        <Grid2 container spacing={2} sx={{ margin: "0 auto", padding: 3 }}>
            <Grid2 size={{ xs: 12, sm: 8, md: 6, }} offset={{ xs: 0, sm: 2, md: 3, }}>
                <Typography variant="h5" gutterBottom>
                    Perfil
                </Typography>
                <form onSubmit={handleSaveProfile}>
                    <Grid2 container spacing={2} sx={{ mt: 5 }}>
                        <Grid2 size={{ xs: 12, sm: 12, md: 12, }}>
                            <TextField
                                label="Name"
                                fullWidth
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 12, md: 12, }}>
                            <TextField
                                label="Email"
                                fullWidth
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Grid2>
                        <Grid2 size={{ xs: 12, sm: 12, md: 12, }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setPasswordModalOpen(true)}
                            >
                                Alterar Senha
                            </Button>
                        </Grid2>
                    </Grid2>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ marginTop: 5 }}
                    >
                        Atualizar Perfil
                    </Button>
                </form>

                {/* Modal de alteração de senha */}
                <ChangePassword
                    open={passwordModalOpen}
                    onClose={() => setPasswordModalOpen(false)}
                />
            </Grid2>
        </Grid2>
    );
};

export default EditProfile;