import React, { createContext, useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./firebaseConfig";
import { getDoc, doc } from "firebase/firestore";

export const AuthContext = createContext();

export const rolesEnum = {
  "admin": "admin",
  "contributor": "contributor",
  "user": "user",
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Recupera o papel do usuário
        const userRole = await fetchUserRole(currentUser.uid);
        setRole(userRole);
      } else {
        setUser(null);
        setRole(null);
      }
      setLoading(false);
    })
    return () => unsubscribe();
  }, []);

  const fetchUserRole = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, "users", uid));
      if (userDoc.exists()) {
        return userDoc.data().role;
      }
      return null;
    } catch (error) {
      console.error("Erro ao buscar papel do usuário:", error.message);
      return null;
    }
  };

  const hasPermission = (requiredRole) => {
    if (!user || !role) return false;

    const roleHierarchy = {
      user: 1,
      contributor: 2,
      admin: 3,
    };

    return roleHierarchy[role] >= roleHierarchy[requiredRole];
  };

  return (
    <AuthContext.Provider value={{ user, role, loading, hasPermission }}>
      {children}
    </AuthContext.Provider>
  );
};