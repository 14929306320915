import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Modal,
    TextField,
    Typography,
    Autocomplete,
    Tooltip,
    IconButton,
    Grid2,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { collection, getDocs, doc, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { toast } from "react-toastify";
import { extractKMLGeoData } from "../utils/geoDataLoader";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import UploadIcon from '@mui/icons-material/CloudUpload';
import MapaModal from "./MapaModal";
import countryBorders from "../countries.json"



const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const PlaceModal = ({ open, onClose, place, onSave }) => {
    const [placeData, setPlaceData] = useState({
        mapId: "",
        leader: "",
        regime: "",
    });
    const [geoDataList, setGeoDataList] = useState([]);
    const [openMapaModal, setOpenMapaModal] = useState(false);


    useEffect(() => {
        // Carregar lista de mapas (geoData) do Firestore
        const fetchGeoData = async () => {
            const querySnapshot = await getDocs(collection(db, "geoData"));
            const geoData = querySnapshot.docs.map((doc) => ({
                mapId: doc.id,
                ...doc.data(),
            }));
            const countries = countryBorders.features.map((f) => {
                return {
                    "name": f.properties.name, 
                    "mapId": f.properties.iso_a3,
                    "geometry": f.properties.geometry,
                }
            })
            //setGeoDataList(geoData);
            setGeoDataList([...geoData, ...countries]);
        };
        fetchGeoData();
    }, []);

    useEffect(() => {
        if (place) {
            // Se estiver editando, preenche os dados do Place
            setPlaceData(place);
        } else {
            // Se for adicionar um novo Place, reseta o estado
            setPlaceData({ mapId: "", leader: "", regime: "" });
        }
    }, [place]);

    // Fechar o modal do mapa
    const handleCloseMapModal = () => {
        setOpenMapaModal(false);
    }

    // Abrir o modal do mapa
    const handleOpenMapModal = () => {
        setOpenMapaModal(true);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPlaceData((prev) => ({ ...prev, [name]: value }));
    };

    const handleMapSelection = (event, newValue) => {
        setPlaceData((prev) => ({
            ...prev,
            mapId: newValue ? newValue.mapId : "",
        }));
    };

    const handleFileUpload = async (file) => {
        try {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(e.target.result, "text/xml");

                // Use extractKMLGeoData para processar o arquivo KML
                const { maps, errors } = extractKMLGeoData(xmlDoc);

                if (errors.length > 0) {
                    errors.forEach((error) => toast.error(`Erro no KML: ${error}`));
                }

                // Adicionar mapas no Firestore
                const addedMaps = [];
                for (const map of maps) {
                    const { mapId, ...data } = map;
                    try {
                        await setDoc(doc(db, "geoData", mapId), data);
                        addedMaps.push(map);
                        toast.success(`Mapa "${mapId}" adicionado com sucesso!`);
                    } catch (err) {
                        console.error(`Erro ao salvar "${mapId}" no Firestore:`, err);
                        toast.error(`Erro ao adicionar o mapa "${mapId}".`);
                    }
                }

                // Atualiza a lista de geoData e o estado do Autocomplete
                setGeoDataList((prev) => [...addedMaps, ...prev]);
            };
            reader.readAsText(file);
        } catch (error) {
            toast.error("Erro ao processar o arquivo KML.");
            console.error(error);
        }
    };

    const handleSubmit = () => {
        if (!placeData.mapId || !placeData.leader) {
            toast.error("Os campos mapId e leader são obrigatórios.");
            return;
        }
        onSave(placeData); // Salva os dados
        onClose(); // Fecha o modal
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={modalStyle}>
                    <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                        {place ? "Editar Região" : "Adicionar Nova Região"}
                    </Typography>

                    <Grid2 container spacing={1} sx={{ mb: 2 }}>
                        <Grid2 size="grow">
                            <Autocomplete
                                options={geoDataList}
                                getOptionLabel={(option) => option.name || ""}
                                isOptionEqualToValue={(option, value) => option.mapId === value?.mapId}
                                value={geoDataList.find((item) => item.mapId === placeData.mapId) || null}
                                onChange={handleMapSelection}
                                renderInput={(params) => <TextField {...params} label="Mapa" />}
                            />
                        </Grid2>
                        <Grid2 size="auto">
                            <Tooltip title="Upload Mapas">
                                <IconButton
                                    component="label"
                                    variant="contained"
                                    color="primary"
                                >
                                    <UploadIcon />
                                    <VisuallyHiddenInput
                                        type="file"
                                        accept=".kml"
                                        onChange={(e) => handleFileUpload(e.target.files[0])}
                                    />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Ver Mapa">
                                <IconButton aria-label="Ver Mapa"
                                    color="primary"
                                    onClick={handleOpenMapModal}
                                    disabled={!placeData.mapId}
                                >
                                    <TravelExploreIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid2>
                    </Grid2>

                    <TextField
                        label="Leader"
                        name="leader"
                        value={placeData.leader}
                        onChange={handleChange}
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        label="Regime"
                        name="regime"
                        value={placeData.regime}
                        onChange={handleChange}
                        fullWidth
                    />

                    <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 2 }}>
                        {place ? "Salvar Alterações" : "Adicionar Região"}
                    </Button>
                </Box>
            </Modal>
            <MapaModal
                open={openMapaModal}
                onClose={handleCloseMapModal}
                place={placeData}
            />
        </>
    );
};

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
};

export default PlaceModal;