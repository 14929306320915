import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { TextField, Button, Typography, CircularProgress, Box } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handlePasswordReset = async () => {
        setLoading(true);
        try {
            await sendPasswordResetEmail(auth, email);
            toast.success("E-mail de redefinição de senha enviado!");
            navigate("/login"); // Redireciona para a página de login
        } catch (error) {
            console.error("Erro ao enviar e-mail de redefinição:", error.message);
            toast.error("Erro ao enviar e-mail. Verifique o e-mail digitado.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                maxWidth: "400px",
                margin: "auto",
                marginTop: "50px",
                padding: "20px",
                backgroundColor: "#333",
                borderRadius: "8px",
                boxShadow: 3,
            }}
        >
            <Typography variant="h4" gutterBottom>
                Redefinir Senha
            </Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
                Insira seu e-mail para receber um link de redefinição de senha.
            </Typography>
            <form onSubmit={(e) => e.preventDefault()}>
                <TextField
                    label="E-mail"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    required
                    sx={{ mb: 2 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePasswordReset}
                    disabled={loading || !email}
                    fullWidth
                >
                    {loading ? <CircularProgress size={24} /> : "Enviar Link"}
                </Button>
            </form>
            <Button
                variant="text"
                onClick={() => navigate("/login")}
                fullWidth
                sx={{ mt: 2 }}
            >
                Voltar para o Login
            </Button>
        </Box>
    );
};

export default ForgotPassword;