import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddLocationIcon from '@mui/icons-material/AddLocationAlt';
import MapIcon from '@mui/icons-material/Map';
import PlaceModal from "./PlaceModal"; // Importando o modal centralizado
import MapaModal from "./MapaModal"

const PlaceTable = ({ places, onUpdatePlaces }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openMapaModal, setOpenMapaModal] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState();

  // Abrir o modal do mapa
  const handleOpenMapModal = (place) => {
    setSelectedPlace(place);
    setOpenMapaModal(true);
  }
  
  // Fechar o modal do mapa
  const handleCloseMapModal = () => {
    setOpenMapaModal(false);
    setSelectedPlace(); // Limpa a seleção quando o modal for fechado
  }

  // Abrir modal com ou sem um lugar selecionado
  const handleOpenModal = (place = null) => {
    setSelectedPlace(place); // Define o lugar selecionado (para edição) ou null (para adição)
    setOpenModal(true);
  };

  // Fechar modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPlace(); // Limpa a seleção quando o modal for fechado
  };

  // Salvar lugar (adicionar ou editar)
  const handleSavePlace = (placeData) => {
    if (selectedPlace) {
      // Atualizar lugar existente
      const updatedPlaces = places.map((place) =>
        place.mapId === selectedPlace.mapId ? { ...place, ...placeData } : place
      );
      onUpdatePlaces(updatedPlaces);
    } else {
      // Adicionar novo lugar
      const updatedPlaces = [...places, placeData];
      onUpdatePlaces(updatedPlaces);
    }
    handleCloseModal(); // Fecha o modal após salvar
  };

  // Deletar lugar
  const handleDeletePlace = (mapId) => {
    const updatedPlaces = places.filter((place) => place.mapId !== mapId);
    onUpdatePlaces(updatedPlaces);
  };

  return (
    <Box>
      <Box sx={{
        width: "100%", display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <Tooltip title="Adicionar região">
          <IconButton variant="contained" color="primary" aria-label="delete" onClick={() => handleOpenModal()}>
            <AddLocationIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Região (ID)</strong></TableCell>
              <TableCell><strong>Líder</strong></TableCell>
              <TableCell><strong>Regime</strong></TableCell>
              <TableCell><strong>Ações</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {places.map((place) => (
              <TableRow key={place.mapId}>
                <TableCell>{place.mapId}</TableCell>
                <TableCell>{place.leader}</TableCell>
                <TableCell>{place.regime}</TableCell>
                <TableCell>
                  <Tooltip title="Ver Mapa">
                    <IconButton color="primary" onClick={() => handleOpenMapModal(place)}>
                      <MapIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar região">
                    <IconButton color="primary" onClick={() => handleOpenModal(place)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Deletar região">
                    <IconButton color="error" onClick={() => handleDeletePlace(place.mapId)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal para Adicionar ou Editar Lugar */}
      <PlaceModal
        open={openModal}
        onClose={handleCloseModal}
        place={selectedPlace}
        onSave={handleSavePlace}
      />
      <MapaModal 
        open={openMapaModal}
        onClose={handleCloseMapModal}
        place={selectedPlace}
      />
    </Box>
  );
};

export default PlaceTable;