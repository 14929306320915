import React, { useContext } from "react";
import { Fab, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext, rolesEnum } from "../AuthContext";


const FloatingAddButton = () => {
  const navigate = useNavigate();
  const { hasPermission } = useContext(AuthContext); // Obter dados do usuário logado

  // Verificar se o usuário é admin
  const allowed = hasPermission(rolesEnum.contributor); // Supondo que "role" armazena o perfil

  const handleAddEvent = () => {
    if (!allowed) {
      toast.error("Apenas administradores podem adicionar eventos.");
      return;
    }
    navigate("/event"); // Navegar para a página de adicionar evento
  };

  if (!allowed) {
    return null; // Não renderiza o botão se o usuário não for admin ou não estiver logado
  }

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
      }}
    >
      <Fab color="primary" onClick={handleAddEvent}>
        <AddIcon />
      </Fab>
    </Box>
  );
};

export default FloatingAddButton;