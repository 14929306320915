import React, { useState } from "react";
import { auth, db} from "../firebaseConfig";
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile, signOut } from "firebase/auth";
import {
  Box,
  TextField,
  Button,
  Typography,
  Alert,
} from "@mui/material";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import { rolesEnum } from "../AuthContext";


const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);


  const handleSignUp = async (e) => {
    e.preventDefault();
    setError(""); // Limpar mensagens de erro

    if (password !== confirmPassword) {
      setError("As senhas não coincidem.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Envia e-mail de verificação
      await sendEmailVerification(user);

      await updateProfile(user, { displayName: displayName });

      await signOut(auth);

      toast.success("Cadastro realizado com sucesso! Verifique seu e-mail para ativar sua conta.");
      setSuccess(true);
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setDisplayName("");
      // Salva informações adicionais no Firestore
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        email: user.email,
        name: displayName,
        role: rolesEnum.user, // Define o papel inicial como "user"
        createdAt: serverTimestamp(),
      });
      
    } catch (err) {
      console.error("Erro ao cadastrar usuário:", error.message);
      setError("Erro ao cadastrar usuário: " + err.message);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "400px",
        margin: "auto",
        marginTop: "50px",
        padding: "20px",
        backgroundColor: "#333",
        borderRadius: "8px",
        boxShadow: 3,
      }}
    >
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Criar Conta
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">Cadastro realizado com sucesso! Verifique seu e-mail para ativar sua conta.</Alert>}
      <form onSubmit={handleSignUp}>
        <TextField
          fullWidth
          label="Nome"
          type="text"
          variant="outlined"
          margin="normal"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="E-mail"
          type="email"
          variant="outlined"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Senha"
          type="password"
          variant="outlined"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <TextField
          fullWidth
          label="Confirmar Senha"
          type="password"
          variant="outlined"
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            marginTop: 2,
            backgroundColor: "#1976d2",
            "&:hover": { backgroundColor: "#1565c0" },
          }}
        >
          Cadastrar
        </Button>
      </form>
    </Box>
  );
};

export default SignUp;