import React from "react";
import { Box, Typography, Grid2 as Grid,  Container } from "@mui/material";
import Logo from "../logo.svg"; // Substitua pelo caminho da sua logo

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#2c2c2c",
        color: "#f1f1f1",
        py: 4,
        mt: "auto",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Logo */}
          <Grid size={{ xs:12, md:4 }}>
            <Box sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" } }}>
              <img src={Logo} alt="Logo" style={{ width: "50px", height: "auto" }} />
            </Box>
          </Grid>

          {/* Informações de Contato */}
          <Grid size={{ xs:12, md:4 }}>
            <Typography variant="body2" gutterBottom sx={{ color: "#b3b3b3", textAlign: { xs: "center", md: "left" } }}>
              Contato
            </Typography>
            <Box component="ul" sx={{ listStyle: "none", p: 0, m: 0 }}>
              <Typography component="li" variant="body2" sx={{ color: "#b3b3b3", textAlign: { xs: "center", md: "left" } }}>
                Email: horta.vinicius@gmail.com
              </Typography>
            </Box>
          </Grid>

          {/* Copyright */}
          <Grid size={{ xs:12, md:4 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", md: "flex-end" },
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography variant="body2" sx={{ color: "#b3b3b3", textAlign: "center" }}>
                &copy; {new Date().getFullYear()} Mapa Histórico. Todos os direitos reservados.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;