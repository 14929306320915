import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebaseConfig";
import { AuthContext } from "../AuthContext";
import { useContext } from "react";



// Componente para proteger rotas
const PrivateRoute = ({ element: Element, permission }) => {
  const [user, setUser] = useState(null);
  const { hasPermission } = useContext(AuthContext);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser); // Atualiza o estado com o usuário autenticado
    });

    return unsubscribe; // Limpeza do listener
  }, []);

  if (user === null) {
    return <div>Carregando...</div>; // Pode colocar um loading enquanto verifica a autenticação
  }

  if (!user) {
    return <Navigate to="/login" />; // Se o usuário não estiver logado, redireciona para o login
  }

  if (permission && !hasPermission(permission)) {
    return <div>Acesso restrito</div>; // Bloqueia o acesso se não for admin
  }

  return <Element />; // Se estiver logado, renderiza o componente solicitado
};

export default PrivateRoute;